/* Image preload */
body::after{ position:absolute; width:0; height:0; overflow:hidden; z-index:-1; content:url('/public/assets/images/screen-bg-navy.jpg') url('/public/assets/images/screen-bg-yellow-dark.jpg') url('/public/assets/images/screen-bg-violet.jpg') url("/public/assets/images/screen-bg-teal.png") url("/public/assets/images/screen-bg-yellow.jpg") url("/public/assets/images/screen-bg-pink.jpg") url("/public/assets/images/screen-bg-green.jpg") url("/public/assets/images/screen-bg-blue.jpg") url("/public/assets/images/screen-bg-yellow-thick.jpg"); }
/* Screen: Welcome */
.csa-app-welcome { background-color: blue; }
.csa-app-welcome-bg { background: url('/public/assets/images/screen-bg-navy.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-welcome-nav { background: url('/public/assets/images/screen-bg-yellow-dark.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-welcome-logo-samsung { width: 80px; top: 36px; right: 20px; }
.csa-app-welcome-logo-samsung img { max-width: 100%; height: auto; margin: 0 auto; }

/* Screen: Step - Relationship */
.csa-app-step-rel-bg { background: url('/public/assets/images/screen-bg-yellow-dark.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-step-rel-nav { background: url('/public/assets/images/screen-bg-violet.jpg') no-repeat; background-size: cover; background-position: 50%; }

/* Screen: Step - Name */
.csa-app-step-name-bg { background: url('/public/assets/images/screen-bg-violet.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-step-name-nav { background: url('/public/assets/images/screen-bg-teal.png') no-repeat; background-size: cover; background-position: 50%; }

/* Screen: Step - Last Interacted */
.csa-app-step-interact-bg { background: url('/public/assets/images/screen-bg-teal.png') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-step-interact-nav { background: url('/public/assets/images/screen-bg-yellow.jpg') no-repeat; background-size: cover; background-position: 50%; }

/* Screen: Step - Interest */
.csa-app-step-interest-bg { background: url('/public/assets/images/screen-bg-yellow.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-step-interest-nav { background: url('/public/assets/images/screen-bg-pink.jpg') no-repeat; background-size: cover; background-position: 50%; }

/* Screen: Step - Tone */
.csa-app-step-tone-bg { background: url('/public/assets/images/screen-bg-pink.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-step-tone-nav { background: url('/public/assets/images/screen-bg-green.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-step-tone-emojis img.icon { max-width: 40px; height: auto; }
.csa-app-disclaimer-overlay { border-top-left-radius: 40px; border-top-right-radius: 40px; height: 30%; background-color: #fff; background-image: linear-gradient(to top, #b8b8b8, #fff 20%); }
button.csa-app-disclaimer-overlay-close { outline: none; border: none; cursor: pointer; position: absolute; top: 10px; right: 20px; width: 24px; height: 24px; background: transparent; padding: 0; }
button.csa-app-disclaimer-overlay-close img { max-width: 100%; height: auto; margin: 0 auto; } 

/* Screen: Greeting */
.csa-app-greet-bg { background: url('/public/assets/images/screen-bg-green.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-nav { background: url('/public/assets/images/screen-bg-yellow-thick.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-loader-bg { background: rgba(0,0,0,.65); }
.csa-app-greet-connect-bg { width: 60%; bottom: 0; left: 20%; }
.csa-app-greet-stars-group2{ width: 105px; top: 5%; left: 2%; }
.csa-app-greet-stars-group3 { width: 135px; bottom: 12%; right: 1%; }
.csa-app-greet-connect-bg img, .csa-app-greet-stars-group2 img, .csa-app-greet-stars-group3 img { max-width: 100%; height: auto; margin-left: auto; margin-right: auto; }
.csa-app-greet-connect-bg img { bottom: -2px; position: absolute; }
.csa-app-greet-title { font-family: 'SamsungSharpSans'; font-size: 36px; line-height: 1.2; margin: 0 0 15px; text-align: center; }
.csa-app-greet-message { font-family: 'SamsungOne'; font-size: 22px; font-weight: 700; line-height: 1.3; margin: 0; text-align: center; }
.csa-app-greet-message-sm { font-family: 'SamsungOne'; font-size: 18px; font-weight: 700; line-height: 1.3; margin: 0; text-align: center; }

/* Screen: Greeting */
.csa-app-greet-share { border-top-left-radius: 60px; border-top-right-radius: 60px; height: 90%; background-color: #fff; background-image: linear-gradient(to top, #b8b8b8, #fff 20%); }
.csa-app-greet-preview-wrapper { width: calc(100% - 40px); height: calc(100% - 60px);  position: absolute; top: 50px; left: 20px; }
.csa-app-greet-preview { width: 100%; height: 100%; /* border-radius: 60px; */ background: url('/public/assets/images/screen-bg-green.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview.bg-green { background-color: #3dc48a; background: url('/public/assets/images/screen-bg-green.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview.bg-yellow-dark { background-color: #ffbd29; background: url('/public/assets/images/screen-bg-yellow-dark.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview.bg-violet { background-color: #9370f0; background: url('/public/assets/images/screen-bg-violet.jpg') no-repeat; background-size: cover; background-position: 50%; } 
.csa-app-greet-preview.bg-teal { background-color: #33d7bc; background: url('/public/assets/images/screen-bg-teal.png') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview.bg-yellow { background-color: #f7f660; background: url('/public/assets/images/screen-bg-yellow.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview.bg-pink { background-color: #d784fa; background: url('/public/assets/images/screen-bg-pink.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview.bg-yellow-thick { background-color: #ffe63b; background: url('/public/assets/images/screen-bg-yellow-thick.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview.bg-blue { background-color: #0e88f7; background: url('/public/assets/images/screen-bg-blue.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-greet-preview-inner { width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; overflow: hidden; }
.csa-app-greet-preview-content { width: 100%; top: 0; left: 0; }
.csa-app-greet-preview-logo { width: 210px; bottom: 2%; left: 50%; transform: translateX(-105px); }
.csa-app-greet-preview-stars-group2 { width: 105px; top: 3%; left: 2%; }
.csa-app-greet-preview-stars-group3 { width: 105px; bottom: 7%; right: 2%; }
.csa-app-greet-preview-logo img, .csa-app-greet-preview-stars-group2 img, .csa-app-greet-preview-stars-group3 img { max-width: 100%; height: auto; margin-left: auto; margin-right: auto; }
button.csa-app-greet-share-close { outline: none; border: none; cursor: pointer; position: absolute; top: 30px; right: 37.5px; width: 30px; height: 30px; background: transparent; padding: 0; }
button.csa-app-greet-share-close img { max-width: 30px; height: auto; margin: 0 auto; } 
.csa-app-greet-share-title { font-family: 'SamsungSharpSans'; font-size: 24px; line-height: 1.2; margin: 0 0 10px; text-align: center; }
.csa-app-greet-share-message { font-family: 'SamsungOne'; font-size: 18px; font-weight: 700; line-height: 1.3; margin: 0; text-align: center; }
.csa-app-greet-share-message-sm { font-family: 'SamsungOne'; font-size: 15px; font-weight: 700; line-height: 1.3; margin: 0; text-align: center; }
.csa-app-greet-share-info { width: 100%; height: 100%; }
.csa-app-greet-share-info-inner { width: 100%; height: 100%; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }
.csa-app-greet-share-download { width: 100%; text-align: left; font-size: 18px; line-height: 1; margin: 0 0 10px; padding: 0; }
.csa-app-greet-social-label { width: 100%; text-align: left; font-family: 'SamsungSharpSans'; font-size: 18px; line-height: 1; margin: 0 0 10px; padding: 0; }
.csa-app-greet-social-icons { width: 100%; display: flex; justify-content: space-between; }

/* Overlay: Nav Menu */
button.csa-nav-menu { outline: none; border: none; cursor: pointer; position: absolute; z-index: 300; top: 18px; left: 15px; width: 48px; height: 48px; background: transparent; padding: 0; }
button.csa-nav-menu img { max-width: 24px; height: auto; margin: 0 auto; } 
button.csa-nav-menu span.csa-badge { position: absolute; color: #fff; background: red; padding: 2px; border-radius: 50%; font-family: 'SamsungOne'; font-size: 10px;  line-height: 1; width: 16px; height: 16px; top: 10px; right: 0; display: inline-flex; justify-content: center; align-content: center; flex-wrap: wrap; }

/* Overlay: My Rewards */
.csa-app-rewards { z-index: 200; }
.csa-app-rewards-bg { background: url('/public/assets/images/screen-bg-blue.jpg') no-repeat; background-size: cover; background-position: 50%; }
.csa-app-rewards-main { background: #fff; border-radius: 60px;  }
.csa-app-rewards-main::-webkit-scrollbar { display: none; }
.csa-app-rewards-info { -webkit-box-shadow: 0 3px 10px #aaa; -moz-box-shadow: 0 3px 10px #aaa; box-shadow: 0 3px 10px #aaa; }
.csa-app-rewards-offers { overflow-x: hidden; overflow-y: auto; }
.csa-app-rewards-offers::-webkit-scrollbar { display: none; }
.csa-app-rewards-nooffer { display: flex; flex-wrap: wrap; align-items: center; cursor: pointer; padding: 60px 45px; justify-content: center; border: 2px solid #FF008C; border-radius: 30px; }
.csa-app-rewards-item { margin-bottom: 37.5px; }
.csa-app-rewards-item-inner { border-radius: 30px; padding: 7.5px 0; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; }
.csa-app-rewards-item-box { display: flex; flex-wrap: wrap; align-items: center; cursor: pointer; justify-content: space-between; }
.csa-app-rewards-item:last-child { margin-bottom: 0; }
.csa-app-rewards-visual { width: 35%; }
.csa-app-rewards-visual-inner { padding-left: 30px; padding-right: 15px; }
.csa-app-rewards-visual img { max-width: 100%; max-height: 100%; }
.csa-app-rewards-content { width: 65%; }
.csa-app-rewards-content-inner { padding-left: 15px; padding-right: 30px; }
.csa-app-rewards-item:nth-child(even) .csa-app-rewards-item-box { flex-direction: row-reverse; text-align: right; }
.csa-app-rewards-item:nth-child(even) .csa-app-rewards-visual-inner { padding-left: 15px; padding-right: 30px; }
.csa-app-rewards-item:nth-child(even) .csa-app-rewards-content-inner { padding-left: 30px; padding-right: 15px; }
.csa-app-rewards-content-code { font-family: 'SamsungOne'; font-size: 27px; font-weight: 700; line-height: 1; margin: 0; padding: 0; color: #000; }
.csa-app-rewards-content-text { font-family: 'SamsungSharpSans'; font-size: 14px; line-height: 1.2; margin: 0; padding: 10.5px 0; }
.csa-app-rewards-content-cta { font-family: 'SamsungOne'; font-size: 14px; border-radius: 30px; padding: 3px 15px; background-color: transparent; border: 1px solid #000; cursor: pointer; color: #000; text-decoration: none; }
.csa-app-rewards-item-actions { width: 100%; margin: 0; padding: 0; }
.csa-app-rewards-item-actions-inner { max-width: 80%; margin: 0 auto; padding: 0; }
.csa-app-rewards-item-actions-box { width: 100%; padding: 10px 0; border-top: 2px solid #efefef; display: flex; flex-wrap: wrap; align-items: center; cursor: pointer; justify-content: space-between; }
.csa-app-rewards-item-actions-label { padding-right: 15px; }
.csa-code-spl { font-size: 16px; color: #FF008C; }

@media (max-width: 540px) {
  .csa-app-greet-stars-group2{ width: 19.444444vw; }
  .csa-app-greet-stars-group3 { width: 25vw; }
  .csa-app-greet-title { font-size: 8.88889vw; margin: 0 0 4.166667vw; }
  .csa-app-greet-message { font-size: 6.11111vw; }
  .csa-app-greet-message-sm { font-size: 5vw; }
  .csa-app-greet-share { border-top-left-radius: 11.111111vw; border-top-right-radius: 11.111111vw; }
  .csa-app-greet-preview-wrapper { border-radius: 11.111111vw; width: calc(100% - 40px); height: calc(100% - 60px); }
  .csa-app-greet-preview-logo { width: 38.888889vw; transform: translateX(-19.444444vw); }
  .csa-app-greet-preview-stars-group2 { width: 19.444444vw; }
  .csa-app-greet-preview-stars-group3 { width: 19.444444vw; }
  .csa-app-greet-share-title { font-size: 6.666667vw; margin: 0 0 2.777778vw; }
  .csa-app-greet-share-message { font-size: 5vw; }
  .csa-app-greet-share-message-sm { font-size: 4.166667vw; }
  .csa-app-greet-share-download { font-size: 4.166667vw; margin: 0 0 2.777778vw; padding: 0; }
  .csa-app-greet-social-label { font-size: 5vw; margin: 0 0 2.777778vw; }
  button.csa-app-greet-share-close { top: 5.555556vw; right: 6.944444vw; width: 5.555556vw; height: 5.555556vw; }
  button.csa-app-greet-share-close img { max-width: 5.555556vw; } 

  .csa-app-rewards-main { border-radius: 11.111111vw; }
  .csa-app-rewards-info { -webkit-box-shadow: 0 0.833333vw 2.777778vw #aaaaaa; -moz-box-shadow: 0 0.833333vw 2.777778vw #aaa; box-shadow: 0 0.833333vw 2.777778vw #aaa; }
  .csa-app-rewards-nooffer { padding: 11.111111vw 8.333333vw; border-radius: 5.555556vw; }
  .csa-app-rewards-item { margin-bottom: 6.944444vw; }
  .csa-app-rewards-item-inner { border-radius: 5.555556vw; padding: 1.388889vw 0; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1.388889vw 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; }
  .csa-app-rewards-visual-inner { padding-left: 5.555556vw; padding-right: 2.777778vw; }
  .csa-app-rewards-content-inner { padding-left: 2.777778vw; padding-right: 5.555556vw; }
  .csa-app-rewards-item:nth-child(even) .csa-app-rewards-visual-inner { padding-left: 2.777778vw; padding-right: 5.555556vw; }
  .csa-app-rewards-item:nth-child(even) .csa-app-rewards-content-inner { padding-left: 5.555556vw; padding-right: 2.777778vw; }
  .csa-app-rewards-content-code { font-size: 5vw; }
  .csa-app-rewards-content-text { font-size: 3.888889vw; padding: 1.94444vw 0; }
  .csa-app-rewards-content-cta { font-size: 3.333333vw; border-radius: 5.555556vw; padding: 0.555556vw 2.777778vw; }

  .csa-app-disclaimer-overlay { border-top-left-radius: 11.111111vw; border-top-right-radius: 11.111111vw; }
  button.csa-app-disclaimer-overlay-close { outline: none; border: none; cursor: pointer; position: absolute; top: 2.777778vw; right: 5.555556vw; width: 6.666667vw; height: 6.666667vw; }

  .csa-code-spl { font-size: 4.444444vw; color: #FF008C; }
}