
.csa-page-inner { width: 100%; height: 100%; max-width: 540px; margin: 0 auto; padding: 0; position: relative; }
.csa-app-view { width: 100%; height: 100%; overflow-x: hidden; }

.csa-page img.block { max-width: 100%; height: auto; }

/* Buttons */
.csa-page .cta-rounded-black { display: inline-block; padding: 15px 45px; border-radius: 45px; font-family: 'SamsungSharpSans', 'sans-serif'; font-size: 20px; line-height: 1; font-weight: 700; color: #000; border: 2px solid #000000; background-color: transparent; letter-spacing: 1px; transition: all 0.2s ease-in-out; cursor: pointer; }
.csa-page .cta-rounded-black.disabled { color: rgba(0, 0, 0, 0.36); border: 2px solid rgba(0, 0, 0, 0.22); }
.csa-page .cta-contained-black { display: inline-block; padding: 15px 45px; border-radius: 45px; font-family: 'SamsungSharpSans', 'sans-serif'; font-size: 20px; line-height: 1; font-weight: 700; color: #fff; border: 2px solid #000000; background-color: #000000; letter-spacing: 1px; transition: all 0.2s ease-in-out; cursor: pointer; }
.csa-page .cta-contained-black.disabled { color: #ccc; background-color: #999; border: 2px solid #999; }
.csa-page .cta-link-black { display: inline-block; padding: 27px 0 9px; font-family: 'SamsungOne', 'sans-serif'; font-size: 16px; line-height: 1; font-weight: 700; color: #000; border-bottom: 2px solid #000000; background-color: transparent; cursor: pointer; cursor: pointer; }
.csa-page .cta-link-black.disabled { color: rgba(0, 0, 0, 0.36); border-bottom: 2px solid rgba(0, 0, 0, 0.22); }
.csa-page .cta-sans { font-family: 'SamsungSharpSans', 'sans-serif'; letter-spacing: 1px; }
.csa-page .cta-link-icon-black { display: flex; align-items: center; justify-content: center; border: none; background: transparent; font-family: inherit; font-size: 20px; line-height: 1; cursor: pointer; color: #000; padding: 0; }
.csa-page .cta-link-icon-black .icon { width: 30px; height: auto; }
.csa-page .cta-link-icon-black .text { margin: 0 0 0 15px; padding: 0 0 3px; border-bottom: 1px solid #000; color: #000; }
.csa-page .cta-social-icon { border: 0; padding: 0; border-radius: 50%; width: 60px; height: 60px; background: transparent; cursor: pointer; }
.csa-page .cta-social-icon-sm { border: 0; padding: 0; border-radius: 50%; width: 45px; height: 45px; background: transparent; cursor: pointer; }
.csa-page .cta-social-icon img, .csa-page .cta-social-icon-sm img { max-width: 100%; max-height: 100%; }
/* Steps */
.csa-page .bullet { position: relative; display: inline-block; width: 24px; height: 24px; margin: 0 7.5px; padding: 0; }
.csa-page .dot { font-size: 0; line-height: 0; display: block; width: 24px; height: 24px; padding: 5px; cursor: pointer; background: transparent; border: 0; outline: none; background: transparent; }
.csa-page .dot:before { position: absolute; top: 0; left: 0; width: 24px; height: 24px; content: '•'; text-align: center; opacity: .25; color: #000; background: #000; border-radius: 50%; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.csa-page .dot.active:before { opacity: .75; }
.csa-page .dot.current:before { opacity: 1; }
.csa-page .csa-cta-disclaimer { color: #000; text-decoration: none; border-bottom: 1px solid #000; font-size: 14px; line-height: 1; cursor: pointer; }
.csa-page .csa-cta-tnc { color: #000; text-decoration: none; border-bottom: 1px solid #000; font-size: 12px; line-height: 1; cursor: pointer; }

/* Input Fields */
.csa-page .field-item { margin-bottom: 7.5px; }
.csa-page .field-item:before { border-bottom: 2px solid #000; left: 0px; bottom: 0px; content: ""; position: absolute; right: 0px; transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; pointer-events: none; }
.csa-page .field-item:after { border-bottom: 2px solid rgb(25, 118, 210); left: 0px; bottom: 0px; content: ""; position: absolute; right: 0px;transform: scaleX(0); transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; pointer-events: none; }
.csa-page .field-item.error:after { border-bottom: 2px solid #d32f2f; transform: scaleX(1); }
.csa-page .field-item.success:after { border-bottom: 2px solid rgb(46, 125, 50); transform: scaleX(1); }
.csa-page input[type="text"] { font-family: 'SamsungOne'; font-size: 24px; color: #000; padding: 15px 0px 15px; border: 0px; box-sizing: content-box; background: none; height: 1.4375em; margin: 0px; -webkit-tap-highlight-color: transparent; display: block; min-width: 0px; width: 100%; }
.csa-page input[type="text"]:focus { outline: none; } 
.csa-page input[type="text"]::placeholder { color: #777; opacity: 1; }
.csa-page input[type="text"]:-ms-input-placeholder { color: #777; }
.csa-page input[type="text"]::-ms-input-placeholder { color: #777; }
.csa-page .error-msg { min-height: 36px; }
.csa-page .field-range-item { margin-bottom: 30px; }
.csa-page input[type=range] { -webkit-appearance: none; background-color: #000000; height: 3px !important; padding: 0; width: 100%; position: relative; cursor: pointer; }
.csa-page input[type=range]::-webkit-slider-thumb { -webkit-appearance: none; background-color: #000000; width: 42px; height: 42px; border-radius: 50%; position: relative; z-index: 100; }

@media (max-width: 540px) {
  .csa-page .cta-rounded-black { padding: 2.777778vw 8.333333vw; border-radius: 8.333333vw; font-size: 4.444444vw; }
  .csa-page .cta-contained-black { padding: 2.777778vw 8.333333vw; border-radius: 8.333333vw; font-size: 4.444444vw; }
  .csa-page .cta-link-black { padding: 5vw 0 1.666667vw; font-size: 3.888889vw; }
  .csa-page .cta-link-icon-black { font-size: 4.444444vw; }
  .csa-page .cta-link-icon-black .icon { width: 5.555556vw; }
  .csa-page .cta-link-icon-black .text { margin: 0 0 0 2.777778vw; padding: 0 0 0.555556vw; }
  .csa-page .cta-social-icon { width: 11.111111vw; height: 11.111111vw; }
  .csa-page .cta-social-icon-sm { width: 8.333333vw; height: 8.333333vw; }
  .csa-page .bullet { width: 4.444444vw; height: 4.444444vw; margin: 0 1.388889vw; }
  .csa-page .dot { width: 4.444444vw; height: 4.444444vw; }
  .csa-page .dot:before { width: 4.444444vw; height: 4.444444vw; }
  .csa-page .field-item { margin-bottom: 1.388889vw; }
  .csa-page input[type="text"] { font-size: 5vw; padding: 2.777778vw 0 2.777778vw; height: 1.4375em; }
  .csa-page .error-msg { min-height: 6.666667vw; }
  .csa-page .field-range-item { margin-bottom: 5.555556vw; }
  .csa-page input[type=range]::-webkit-slider-thumb { width: 7.77778vw; height: 7.77778vw; }
  .csa-page .csa-cta-disclaimer { font-size: 3.888889vw; }
  .csa-page .csa-cta-disclaimer { font-size: 3.333333vw; }
}