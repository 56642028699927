@charset "utf-8";
/* Font Family */
@font-face{font-display:swap;font-family:'SamsungOne';font-style:normal;font-weight:normal;src:local('SamsungOne'),url('../fonts/SamsungOne-400.woff2') format('woff2'),url('../fonts/SamsungOne-400.woff') format('woff'),url('../fonts/SamsungOne-400.ttf') format('truetype'),url('../fonts/SamsungOne-400.eot?#iefix') format('embedded-opentype')}
@font-face{font-display:swap;font-family:'SamsungOne';font-style:normal;font-weight:bold;src:local('SamsungOne'),url('../fonts/SamsungOne-700.woff2') format('woff2'),url('../fonts/SamsungOne-700.woff') format('woff'),url('../fonts/SamsungOne-700.ttf') format('truetype'),url('../fonts/SamsungOne-700.eot?#iefix') format('embedded-opentype')}
@font-face{font-display:swap;font-family:'SamsungSharpSans';font-style:normal;font-weight:bold;src:local('SamsungSharpSans'),url('../fonts/SamsungSharpSansBd.woff2') format('woff2'),url('../fonts/SamsungSharpSansBd.woff') format('woff'),url('../fonts/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype')}
@font-face{font-display:swap;font-family:'SamsungSharpSans';font-style:normal;font-weight:normal;src:local('SamsungSharpSans'),url('../fonts/SamsungSharpSansBd.woff2') format('woff2'),url('../fonts/SamsungSharpSansBd.woff') format('woff'),url('../fonts/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype')}

html { position: static; overflow-y: hidden; height: 100%; min-height: 100vh; font-family: 'SamsungOne'; }
body { position: fixed; width: 100%; height: 100%;  min-height: 100vh; min-height: -webkit-fill-available; -webkit-user-select: none; -moz-user-select: none; user-select: none; -webkit-tap-highlight-color: rgba(0,0,0,0); -webkit-touch-callout: none; background-color: #f7f7f7; overflow: hidden; margin: 0; line-height: inherit; font-family: 'SamsungOne'; }

* { box-sizing: border-box; touch-action: pan-x pan-y; }

.csa-full-screen { min-height: 100vh; min-height: -webkit-fill-available; }
.csa-page { width: 100%; height: 100%; margin: 0; padding: 0; }

.csa-page .relative { position: relative; }
.csa-page .absolute { position: absolute; }
.csa-page .inset-0 { top: 0; bottom: 0; left: 0; right: 0; }
.csa-page .left-0 { left: 0; }
.csa-page .right-0 { right: 0; }
.csa-page .top-0 { top: 0; }
.csa-page .bottom-0 { bottom: 0; }

.csa-page h1 { font-family: 'SamsungSharpSans'; font-size: 50px; line-height: 1.2; margin: 0 0 30px 0; }
.csa-page h2 { font-family: 'SamsungSharpSans'; font-size: 50px; line-height: 1.2; margin: 0 0 22.5px 0; }
.csa-page h4 { font-family: 'SamsungSharpSans'; font-size: 36px; line-height: 1.2; margin: 0 0 15px 0; }
.csa-page p { font-family: 'SamsungOne'; font-size: 24px; line-height: 1.33; margin: 0 0 15px 0; }
.csa-page .text-xl { font-size: 36px; line-height: 1.2; margin: 0; }
.csa-page .text-lg { font-size: 30px; line-height: 1.2; margin: 0; }
.csa-page .text-md { font-size: 24px; line-height: 1.2; margin: 0; }
.csa-page .text-sm { font-size: 20px; line-height: 1.2; margin: 0; }
.csa-page .text-xs { font-size: 16px; line-height: 1.2; margin: 0; }

.csa-page .text-bold { font-weight: 700; }

.csa-page .text-sans { font-family: 'SamsungSharpSans'; }

.csa-page .text-black { color: #000000; }
.csa-page .text-white { color: #ffffff; }
.csa-page .text-error { color: #d32f2f; }

.csa-page .text-center { text-align: center; }
.csa-page .text-left { text-align: left; }
.csa-page .text-right { text-align: right; }

.csa-page .w-full { width: 100%; }
.csa-page .w-logo { width: 75%; }
.csa-page .h-full { height: 100%; }

.csa-page .ml-auto { margin-left: auto; }
.csa-page .mr-auto { margin-right: auto; }

.csa-page .px-0 { padding-left: 0px; padding-right: 0px; }
.csa-page .px-10 { padding-left: 15px; padding-right: 15px; }
.csa-page .px-20 { padding-left: 30px; padding-right: 30px; }
.csa-page .px-30 { padding-left: 45px; padding-right: 45px; }
.csa-page .px-40 { padding-left: 60px; padding-right: 60px; }
.csa-page .px-50 { padding-left: 75px; padding-right: 75px; }
.csa-page .px-60 { padding-left: 90px; padding-right: 90px; }

.csa-page .py-0 { padding-top: 0px; padding-bottom: 0px; }
.csa-page .py-10 { padding-top: 15px; padding-bottom: 15px; }
.csa-page .py-20 { padding-top: 30px; padding-bottom: 30px; }
.csa-page .py-30 { padding-top: 45px; padding-bottom: 45px; }
.csa-page .py-40 { padding-top: 60px; padding-bottom: 60px; }
.csa-page .py-50 { padding-top: 75px; padding-bottom: 75px; }
.csa-page .py-60 { padding-top: 90px; padding-bottom: 90px; }

.csa-page .pt-0 { padding-top: 0px; }
.csa-page .pt-10 { padding-top: 15px; }
.csa-page .pt-20 { padding-top: 30px; }
.csa-page .pt-30 { padding-top: 45px; }
.csa-page .pt-40 { padding-top: 60px; }
.csa-page .pt-50 { padding-top: 75px; }
.csa-page .pt-60 { padding-top: 80px; }
.csa-page .pt-80 { padding-top: 120px; }
.csa-page .pt-100 { padding-top: 140px; }
.csa-page .pt-120 { padding-top: 150px; }
.csa-page .pt-140 { padding-top: 180px; }

.csa-page .pb-0 { padding-bottom: 0px; }
.csa-page .pb-5 { padding-bottom: 7.5px; }
.csa-page .pb-10 { padding-bottom: 15px; }
.csa-page .pb-20 { padding-bottom: 30px; }
.csa-page .pb-30 { padding-bottom: 45px; }
.csa-page .pb-40 { padding-bottom: 60px; }
.csa-page .pb-50 { padding-bottom: 75px; }
.csa-page .pb-60 { padding-bottom: 90px; }
.csa-page .pb-100 { padding-bottom: 150px; }

.csa-page .flex { display: flex; }
.csa-page .flex-wrap { flex-wrap: wrap; }
.csa-page .flex-nowrap { flex-wrap: nowrap; }
.csa-page .justify-start { justify-content: flex-start; }
.csa-page .justify-end { justify-content: flex-end; }
.csa-page .justify-center { justify-content: center; }
.csa-page .justify-between { justify-content: space-between; }
.csa-page .justify-evenly { justify-content: space-evenly; }
.csa-page .justify-stretch { justify-content: stretch; }
.csa-page .items-start { align-items: flex-start; }
.csa-page .items-end { align-items: flex-end; }
.csa-page .items-center { align-items: center; }

.csa-page .csa-disclaimer { font-size: 12px; line-height: 1.1; }

@media (max-width: 540px) {
  .csa-page h1 { font-size: 9.444444vw; margin: 0 0 4.166667vw 0; }
  .csa-page h2 { font-size: 10.555556vw; margin: 0 0 4.166667vw 0; }
  .csa-page h4 { font-size: 6.666667vw; margin: 0 0 2.777778vw 0; }
  .csa-page p { font-size: 5vw; margin: 0 0 2.777778vw 0; }
  .csa-page .text-xl { font-size: 6.666667vw; }
  .csa-page .text-lg { font-size: 5.555556vw; }
  .csa-page .text-md { font-size: 5vw; }
  .csa-page .text-sm { font-size: 4.444444vw; }
  .csa-page .text-xs { font-size: 3.888889vw; }
  .csa-page .px-0 { padding-left: 0; padding-right: 0; }
  .csa-page .px-10 { padding-left: 2.777778vw; padding-right: 2.777778vw; }
  .csa-page .px-20 { padding-left: 5.555556vw; padding-right: 5.555556vw; }
  .csa-page .px-30 { padding-left: 8.333333vw; padding-right: 8.333333vw; }
  .csa-page .px-40 { padding-left: 11.111111vw; padding-right: 11.111111vw; }
  .csa-page .px-50 { padding-left: 13.88889vw; padding-right: 13.88889vw; }
  .csa-page .px-60 { padding-left: 16.666667vw; padding-right: 16.666667vw; }
  .csa-page .py-0 { padding-top: 0; padding-bottom: 0; }
  .csa-page .py-10 { padding-top: 2.777778vw; padding-bottom: 2.777778vw; }
  .csa-page .py-20 { padding-top: 5.555556vw; padding-bottom: 5.555556vw; }
  .csa-page .py-30 { padding-top: 8.333333vw; padding-bottom: 8.333333vw; }
  .csa-page .py-40 { padding-top: 11.111111vw; padding-bottom: 11.111111vw; }
  .csa-page .py-50 { padding-top: 13.88889vw; padding-bottom: 13.88889vw; }
  .csa-page .py-60 { padding-top: 16.666667vw; padding-bottom: 16.666667vw; }
  .csa-page .pt-0 { padding-top: 0; }
  .csa-page .pt-10 { padding-top: 2.777778vw; }
  .csa-page .pt-20 { padding-top: 5.555556vw; }
  .csa-page .pt-30 { padding-top: 8.333333vw; }
  .csa-page .pt-40 { padding-top: 11.111111vw; }
  .csa-page .pt-50 { padding-top: 13.88889vw; }
  .csa-page .pt-60 { padding-top: 16.666667vw; }
  .csa-page .pt-80 { padding-top: 22.222222vw; }
  .csa-page .pt-100 { padding-top: 27.777778vw; }
  .csa-page .pt-120 { padding-top: 27.777778vw; }
  .csa-page .pt-140 { padding-top: 38.888889vw; }
  .csa-page .pb-0 { padding-bottom: 0; }
  .csa-page .pb-5 { padding-bottom: 1.388889vw; }
  .csa-page .pb-10 { padding-bottom: 2.777778vw; }
  .csa-page .pb-20 { padding-bottom: 5.555556vw; }
  .csa-page .pb-30 { padding-bottom: 8.333333vw; }
  .csa-page .pb-40 { padding-bottom: 11.111111vw; }
  .csa-page .pb-50 { padding-bottom: 13.88889vw; }
  .csa-page .pb-60 { padding-bottom: 16.666667vw; }
  .csa-page .pb-100 { padding-bottom: 27.777778vw; }
  .csa-page .w-logo { width: 75%; }
}